<template>
  <div id="app" class="app" v-if="isReady">
    <transition name="fade" mode="out-in">
      <LoginPage v-if="showLogin" />
      <router-view
        v-else
        class="page"
        :key="$route.fullPath"
      />
    </transition>
    <Logo />
    <Menu :isOpen="menuIsOpen"/>
    <transition name="fade-delay">
      <MenuButton
        v-show="$route.name !== 'Home' && !showLogin"
        class="menu-toggle"
        :menuIsOpen="menuIsOpen"
        @click.native="onMenuToggle"
      />
    </transition>
    <transition name="fade-delay">
      <CategoryFilters
        v-if="$route.name === 'ProjectsList'"
        :isOpen="filtersIsOpen"
      />
    </transition>
    <transition name="fade-delay">
      <Cta
        v-if="$route.name === 'ProjectsList' && !showLogin"
        class="filter-toggle"
        @click.native="onFiltersToggle"
      >
        <template v-if="$root.platform.mobile">
          <FilterIcon class="filter-icon"/>
        </template>
        <template v-else>
          {{$root.Settings.filterLabel}}
        </template>
      </Cta>
    </transition>
    <portal-target name="modal"/>
    <BannerCookie />
    <VersionInfo
      v-if="$root.process.env.SERVICE_STAGE !== 'production'"
      :version="$root.process.env.GIT_VERSION"
      :commit-hash="$root.process.env.GIT_COMMIT_HASH"
    />
  </div>
</template>

<script>
import VersionInfo from '@monogrid/vue-lib/lib/components/VersionInfo'
import ShareMixin from '@/mixins/share-mixin'
import MenuButton from '@/molecules/MenuButton'
import CategoryFilters from '@/molecules/CategoryFilters'
import BannerCookie from './atoms/BannerCookie'
import Logo from '@/atoms/Logo'
import Menu from '@/atoms/Menu'
import Cta from '@/atoms/Cta'
import FilterIcon from '@/assets/svg/icon-filter.svg'

let initializedModernizr = false
const htmlElement = document.getElementsByTagName('html')[0]

export default {
  name: 'App',
  mixins: [ShareMixin],
  components: {
    MenuButton,
    Logo,
    Menu,
    Cta,
    CategoryFilters,
    VersionInfo,
    FilterIcon,
    BannerCookie,
    LoginPage: () => import(/* webpackChunkName: "page-login" */ './pages/LoginPage')
  },

  data () {
    return {
      menuIsOpen: false,
      filtersIsOpen: false,
      isReady: false
    }
  },

  computed: {
    showLogin () {
      return this.$root.Settings.forceLogin && !this.$root.userIsLogged && !this.$root.platform.prerenderer
    }
  },
  mounted () {
    this.$events.on('toggle-scroll', this.toggleScroll)
    this.$events.on('change:projects-filter', this.onFilterChange)
    window.addEventListener('scroll', this.onScroll, { passive: true })
  },
  updated () {
    // if not prerenderer
    if (!this.$root.platform.prerenderer && window.Modernizr && !initializedModernizr) {
      // add custom modernizr tests
      initializedModernizr = true
      // window.Modernizr.addAsyncTest('avif', this.testAvif)
      this.$root.platform.desktop = !this.$root.platform.mobile && !this.$root.platform.tablet
      for (const key in this.$root.platform) {
        window.Modernizr.addTest(key, () => {
          return this.$root.platform[key]
        })
      }
    }
  },
  watch: {
    '$root.Settings' (value) {
      if (value) {
        this.isReady = true
        if (this.$root.Settings.forceLogin && this.$root.userIsLogged) {
          this.$root.sendEvent({
            event: 'refresh',
            category: 'login',
            action: this.$root.userIsLogged,
            label: 'User logged in'
          })
        }
      }
    },
    '$route.fullPath' (value) {
      this.menuIsOpen = false
      this.toggleScroll(false)
    }
  },
  methods: {
    toggleScroll (state) {
      htmlElement.classList.toggle('disable-scroll', state)
    },
    onMenuToggle () {
      this.menuIsOpen = !this.menuIsOpen
      if (this.filtersIsOpen) {
        this.filtersIsOpen = false
      }
    },
    onFiltersToggle () {
      this.filtersIsOpen = !this.filtersIsOpen
      if (this.menuIsOpen) {
        this.menuIsOpen = false
      }
    },
    onFilterChange () {
      this.disableAutoFilterClose = true
      clearTimeout(this.timeoutAutoClose)
      this.timeoutAutoClose = setTimeout(() => {
        this.disableAutoFilterClose = false
      }, 1000)
    },
    onScroll (e) {
      this.lastY = this.lastY || 0
      const delta = Math.abs(this.lastY - window.pageYOffset)
      this.lastY = window.pageYOffset
      if (delta < 10) {
        return
      }
      if (this.menuIsOpen) {
        this.menuIsOpen = false
      }

      if (this.filtersIsOpen && !this.disableAutoFilterClose) {
        this.filtersIsOpen = false
      }
    }
  },
  metaInfo () {
    const title = this.$root.Settings ? this.$root.Settings.title : 'Fedrigoni Top Awards'

    const baseShare = {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: '',
      // all titles will be injected into this template
      titleTemplate: (titleChunk) => (titleChunk ? `${titleChunk} - ${title}` : title),
      base: { href: '/' },
      meta: [
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        // twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@fedrigonipapers' },
        { name: 'twitter:creator', content: '@fedrigonipapers' },
        // og tags
        { vmid: 'ogtype', property: 'og:type', content: 'website' },
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: title },
        { vmid: 'ogurl', property: 'og:url', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + this.$route.fullPath }
      ],
      htmlAttrs: {
        lang: this.$language.current
      }
    }

    const share = this.$root.Settings ? this.getShare({ description: this.$root.Settings.shareDescription, image: this.$root.Settings.shareImage }) : { meta: [] }

    return {
      ...baseShare,
      ...share,
      meta: [
        ...baseShare.meta,
        ...share.meta
      ]
    }
  }
}
</script>

<style lang="scss">
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  @include generate-grid();

  @font-face {
    font-display: swap;
    font-family: 'Fedrigoni-Banner';
    font-style: normal;
    font-weight: 500;
    src:
      url('assets/fonts/FormaDJRBannerFedrigoni-Medium.woff2') format('woff2'),
      url('assets/fonts/FormaDJRBannerFedrigoni-Medium.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Fedrigoni';
    font-style: normal;
    font-weight: 700;
    src:
      url('assets/fonts/FormaDJRMicroFedrigoni-Bold.woff2') format('woff2'),
      url('assets/fonts/FormaDJRMicroFedrigoni-Bold.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Fedrigoni';
    font-style: normal;
    font-weight: 400;
    src:
      url('assets/fonts/FormaDJRMicroFedrigoni-Regular.woff2') format('woff2'),
      url('assets/fonts/FormaDJRMicroFedrigoni-Regular.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Fedrigoni';
    font-style: normal;
    font-weight: 500;
    src:
      url('assets/fonts/FormaDJRMicroFedrigoni-Medium.woff2') format('woff2'),
      url('assets/fonts/FormaDJRMicroFedrigoni-Medium.woff') format('woff');
  }

  html {
    width: 100%;
    min-height: 100%;

    font-family: 'Fedrigoni';
    font-size: rem($font-size-root);

    overflow-x: hidden;
    overscroll-behavior: none;
  }

  body::after {
    position: fixed;
    z-index: -1;
    top: rem(-200px);
    right: rem(-200px);
    bottom: rem(-200px);
    left: rem(-200px);

    display: block;

    animation: grain 0.4s steps(3) infinite;

    background-image: url('/static/noise-100.png');
    content: '';
    transform: translateZ(0);
  }

  @keyframes grain {
    0%,
    100% {transform: translate3d(0, 0, 0);}
    33% {transform: translate3d(5%, -5%, 0);}
    66% {transform: translate3d(-2%, 3%, 0);}
  }

  body::before {
    @include themify-rule('background-color', 'bg', 'base');
    position: fixed;
    z-index: $z-header;
    top: 0;
    right: 0;
    left: 0;
    height: rem(52px);

    background-image: url('/static/noise-100.png');
    content: '';
    transition: background-color 0.3s $loading-delay;

    html[data-theme='dark'] & {
      @include themify-rule('background-color', 'bg', 'base-dark');
    }

    html[data-page='Home'] &,
    html[data-page='Login'] & {
      background-color: transparent;
    }

    html[data-page='Home'] & {
      background-image: none;
    }

    html[data-page='ProjectsList'][data-theme='dark'] & {
      @include themify-rule('background-color', 'bg', 'projects-dark');
    }

    html[data-page='BehindTheAward'] & {
      @include themify-rule('background-color', 'bg', 'behind');
    }

    html[data-page='BehindTheAward'][data-theme='dark'] & {
      @include themify-rule('background-color', 'bg', 'behind-dark');
    }
    @screen md {
      display: none;
    }

    @each $cat, $palette in $page-types {
      html[data-page='SingleProject'][data-cat='#{$cat}'] & {
        @include themify-rule('background-color', 'bg', 'cat-'+$palette);

      }
      html[data-page='SingleProject'][data-cat='#{$cat}'][data-theme='dark'] & {
        @include themify-rule('background-color', 'bg', 'cat-'+$palette+'-dark');
      }
    }
  }

  html {
    @include themify('base');
    transition: background-color 0.2s, color 0.2s;
    transition-delay: $loading-delay;

    .text-primary,
    .text-secondary {
      transition: color 0.2s;
      transition-delay: $loading-delay;

    }

    &[data-theme='dark'] {
      @include themify('base', true);
    }

  }

  html[data-page='Home'] {
    @include themify-rule('color', 'text', 'home');
  }

  html[data-page='Login'] {
    @include themify-rule('background-color', 'bg', 'base-dark');
    @include themify-rule('color', 'text', 'base-dark');
  }

  html[data-page='ProjectsList'] {
    @include themify('projects');

    &[data-theme='dark'] {
      @include themify('projects', true);
    }
  }

  html[data-page='BehindTheAward'] {
    @include themify('behind');

    &[data-theme='dark'] {
      @include themify('behind', true);
    }
  }

  html[data-page='Event'] {
    @include themify('event');

    &[data-theme='dark'] {
      @include themify('event', true);
    }
  }

  @each $cat, $palette in $page-types {
    html[data-page='SingleProject'][data-cat='#{$cat}'] {
      @include themify('cat-'+$palette);

      &[data-theme='dark'] {
        @include themify('cat-'+$palette, true);
      }
    }
  }

  html.disable-scroll {
    overflow: hidden;
  }

  .page {
    position: relative;
    z-index: $z-page;

    padding-bottom: rem(80px);
  }

  h1 {
    font-family: 'Fedrigoni-Banner';
    font-size: rem(121px);
    font-weight: 500;
    @screen md {
      font-size: rem(417px);
    }
  }

  h2 {
    font-family: 'Fedrigoni-Banner';
    font-size: rem(74px);
    font-weight: 500;
    @screen md {
      font-size: rem(259px);
    }
  }

  h3.big {
    font-size: rem(35px);
    font-weight: 500;
    line-height: 1;
    @screen md {
      font-size: rem(45px);
    }
    @screen lg {
      font-size: rem(70px);
    }
    @screen 2xl {
      font-size: rem(108px);
    }
  }

  h3,
  .h3 {
    font-size: rem(30px);
    font-weight: 400;
    line-height: 1;
    @screen md {
      font-size: rem(45px);
    }
  }

  small {
    font-size: rem(15px);
    font-weight: 500;
  }

  .extra-small {
    font-size: rem(10px);
    font-weight: 500;
  }

  .label {
    font-size: rem(25px);
    font-weight: 500;
  }

  .cta-label {
    font-family: 'Fedrigoni-Banner';
    font-size: rem(15px);
    font-weight: 500;
    letter-spacing: rem(1px);
    text-transform: uppercase;
    white-space: nowrap;

    @screen md {
      font-size: rem(18px);
    }
  }

}

.filter-toggle {
  position: fixed;
  z-index: $z-toggle-menus;
  right: rem(16px);
  bottom: rem(16px);
}

.modal {
  position: fixed;
  z-index: $z-modal;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  align-items: center;
}

.bg-before-secondary {
  @each $cat, $palette in $page-types {
    html[data-page='SingleProject'][data-cat='#{$cat}'] &::before {
      @include themify-rule('background-color', 'bg', 'cat-'+$palette+'-dark');

    }
    html[data-page='SingleProject'][data-cat='#{$cat}'][data-theme='dark'] &::before {
      @include themify-rule('background-color', 'bg', 'cat-'+$palette);
    }
  }
}

.modal-bg {
  position: absolute;
  z-index: 0;
  top: -50%;
  left: -50%;

  width: 100%;
  height: 100%;

  will-change: transform;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    display: block;

    width: 100%;
    height: 0;

    padding-bottom: 100%;

    border-radius: 50%;

    content: '';
    transform: translate(-50%, -50%);

    transform-origin: center;
  }
}

.filter-icon {
  display: inline-block;

  width: 20px;
}

.plus-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  width: rem(30px);

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    right: 0;

    display: block;

    width: 100%;
    height: rem(6px);

    margin-top: rem(-3px);
    margin-left: rem(-15px);

    background: currentColor;
    content: '';
    transition: transform 0.3s, opacity 0.3s;
    will-change: transform;
  }

  &::after {
    transform: rotate(-90deg);
  }

  .header.open & {
    &::after {
      transform: rotate(0deg);
    }

    &::before {
      opacity: 0;
      transform: rotate(180deg);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fade-delay-enter-active,
.fade-delay-leave-active {
  transition: opacity 0.5s $loading-delay;
}

.fade-delay-enter,
.fade-delay-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
