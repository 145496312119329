<template>
  <router-link :to="{name: 'Home'}" class="logo" alt="Homepage" aria-label="Homepage">
    <LogoSVG />
  </router-link>
</template>

<script>
import LogoSVG from '@/assets/svg/logo.svg'
export default {
  name: 'Logo',
  components: { LogoSVG }
}
</script>

<style lang="scss" scoped>
.logo {
  position: fixed;
  z-index: $z-logo;
  top: rem($screen-mobile-distance);
  left: 50%;

  width: rem(110px);
  height: rem(20px);

  transform: translateX(-50%);
  @screen md {
    top: rem($screen-desktop-distance);

    width: rem(136px);
    height: rem(20px);

  }

  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
