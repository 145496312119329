<template>
  <div class="menu">
    <div class="bg"></div>
    <div class="wrapper">
      <div class="container-fluid">
        <div class="menu-header stagger mb-8">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.669 44.909"><g fill="#fff"><path d="M15.809 30.846v14.063h3.526v-4.6h2.35c2.032-.04 4.044-.139 5.339-2.012a4.8 4.8 0 00.837-2.789 5.386 5.386 0 00-.777-2.868 3.245 3.245 0 00-.637-.7 5.4 5.4 0 00-3.665-1.1zm3.526 2.589h2.251a2.748 2.748 0 012.41.776 2.142 2.142 0 01.378 1.275 1.9 1.9 0 01-1.713 2.152 8.424 8.424 0 01-1.195.06h-2.132zM34.107 26.26a10.3 10.3 0 003.559-7.834 11.349 11.349 0 00-2.815-7.49 9.142 9.142 0 00-6.693-2.6 8.61 8.61 0 00-7.49 3.825 11 11 0 00-1.779 6.055 10.872 10.872 0 002.683 7.331 9.189 9.189 0 006.639 2.683 8.875 8.875 0 005.9-1.966m-1.142-7.515a6.184 6.184 0 01-1.487 4.116 4.492 4.492 0 01-3.24 1.408 4.274 4.274 0 01-3.16-1.354 6.538 6.538 0 01-1.567-4.754c0-1.992.664-4.329 2.55-5.365a4.048 4.048 0 012.1-.531 4.849 4.849 0 011.805.346 4.348 4.348 0 011.807 1.407 7.713 7.713 0 011.195 4.728"/><path d="M0 0v6.075h8.042v24.781h7.779V6.075h8V0z"/></g></svg>
          <small style="color: #fff;">supported by HP Indigo</small>
        </div>
        <div class="row">
          <div class="col-md-8 mb-8 md:mb-0">
            <router-link
              v-for="(item, i) in $root.Settings.menuItems"
              :key="i"
              :to="{name: item.route}"
              class="menu-item menu-item-big mb-2 md:mb-4 stagger"
              @click.native="onRouteClick"
            >
              <span class="subtitle">
                {{item.subtitle}}
              </span>
              <span class="title">
                {{item.title}}
              </span>
            </router-link>
          </div>
          <div class="col-md-4 flex flex-col justify-end">
            <div class="menu-item menu-item-small mb-4 md:mb-12">
              <span class="title-small mb-2 md:mb-4 stagger">
                {{$root.Settings.menuLanguageLabel}}
              </span>
              <router-link
                :to="{params: {language: 'it'}}"
                class="small-link stagger mr-4"
                :class="{current: $route.params.language === 'it'}"
              >
                ITA
              </router-link>
              <router-link
                :to="{params: {language: 'en'}}"
                class="small-link stagger"
                :class="{current: $route.params.language === 'en'}"
              >
                ENG
              </router-link>
            </div>
            <div class="menu-item menu-item-small mb-4 md:mb-12">
              <span class="title-small mb-2 md:mb-4 stagger">
                {{$root.Settings.menuSocialLabel}}
              </span>
              <a
                v-for="({type, url}, i) in $root.Settings.menuSocialLinks"
                :key="i"
                :href="url"
                class="small-link stagger mr-4"
                target="_blank"
                rel="noopener"
                :aria-label="type"
                :title="type"
              >
                <IconFacebook v-if="type === 'facebook'"/>
                <IconInstagram v-if="type === 'instagram'"/>
                <IconTwitter v-if="type === 'twitter'"/>
                <IconYoutube v-if="type === 'youtube'"/>
                <IconLinkedin v-if="type === 'linkedin'"/>
              </a>
            </div>
            <div class="menu-item menu-item-small mb-4 md:mb-12">
              <span class="title-small mb-2 md:mb-4 stagger">
                {{$root.Settings.menuContactLabel}}
              </span>
              <small class="stagger contact-text">
                {{$root.Settings.menuContactDescription}}
                <a class="stagger small-link" :href="`mailto:${$root.Settings.menuContactEmail}`">{{$root.Settings.menuContactEmail}}</a>
              </small>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-8  stagger">
            <span class="extra-small">{{$root.Settings.footerText}}</span>
          </div>
          <div class="col-md-4 stagger">
            <span class="extra-small">
              <a target="_blank" rel="noopener" :href="$root.Settings.privacyUrl">Privacy</a>
              <span> - </span>
              <a target="_blank" rel="noopener" :href="$root.Settings.cookiePolicyUrl">Cookie Policy</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import IconFacebook from '@/assets/svg/icon-facebook.svg'
import IconInstagram from '@/assets/svg/icon-instagram.svg'
import IconTwitter from '@/assets/svg/icon-twitter.svg'
import IconYoutube from '@/assets/svg/icon-youtube.svg'
import IconLinkedin from '@/assets/svg/icon-linkedin.svg'

export default {
  name: 'Menu',
  props: {
    isOpen: { type: Boolean }
  },
  components: {
    IconFacebook,
    IconInstagram,
    IconTwitter,
    IconYoutube,
    IconLinkedin
  },
  async mounted () {
    await this.$nextTick()
    this.createTl()
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
  },
  watch: {
    isOpen (value) {
      this.tl && this.tl.tweenTo(value ? 'open' : 'close')
    }
  },
  methods: {
    createTl () {
      this.tl = gsap.timeline({ paused: true })
      this.tl.addLabel('close')
      this.tl.fromTo(this.$el, { autoAlpha: 0, pointerEvents: 'none' }, { autoAlpha: 1, pointerEvents: 'auto', duration: 0.1 })
      this.tl.fromTo(this.$el.querySelector('.bg'), { scale: 0 }, { scale: 1, duration: 0.5 })
      this.tl.fromTo(this.$el.querySelectorAll('.stagger'), { autoAlpha: 0, y: 20 }, { autoAlpha: 1, y: 0, duration: 0.2, stagger: 0.05 }, '-=0.2')
      this.tl.addLabel('open')
    },
    onRouteClick (e) {
      if (e.currentTarget.classList.contains('router-link-exact-active')) {
        window.location.reload()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  position: fixed;

  z-index: $z-menus;
  bottom: rem(32px);
  left: 50%;

  width: 90%;
  max-width: rem(900px);

  padding: rem(16px 16px 24px);

  color: $c-white;
  transform: translateX(-50%);

  @screen md {
    padding: rem(32px 16px 48px);
  }
  @screen lg {
    padding: rem(48px 32px);
  }
}

.menu-header {
  color: $c-white;

  svg {
    display: inline-block;

    width: rem(38px);
    height: rem(45px);

    margin-right: rem(12px);
  }
}

.wrapper {
  max-height: 70vh;

  overflow-y: auto;

  @screen beforeMd {
    padding: rem(24px 0);
    /* stylelint-disable-next-line */
    -webkit-mask-image: -webkit-linear-gradient(top,rgba(0,0,0,0) 0px,#000 20px,#000 calc(100% - 20px),rgba(0,0,0,0) 100%);
  }
}

.container-fluid {
  overflow: hidden;
}

.bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: rem(12px);

  background: $c-black;
  transform-origin: bottom center;
  @screen beforeMd {
    html[data-page='ProjectsList'] & {
      transform-origin: bottom left;
    }
  }
}

.menu-item {
  display: block;

  line-height: 1.1;

  &:last-child {
    margin-bottom: 0 !important;
  }
}

.menu-item-big {
  .title {
    display: inline-block;

    transform: translateX(0);
    transition: transform 0.3s;
  }

  html.desktop &:hover {
    .title {
      transform: translateX(16px);
    }
  }
}

.title {
  font-family: 'Fedrigoni-Banner';
  font-size: rem(36px);
  letter-spacing: 2px;
  @screen md {
    font-size: rem(42px);
  }
  @screen lg {
    font-size: rem(60px);
  }
}

.subtitle {
  display: block;

  color: $c-grey-menu;
  font-size: rem(16px);

  @screen lg {
    font-size: rem(20px);
  }
}

.title-small {
  display: block;

  font-size: rem(20px);
  @screen lg {
    font-size: rem(30px);
  }
}

small,
.small-link {
  display: inline-block;

  color: $c-grey-menu;
  font-size: rem(15px);

  svg {
    display: inline-block;

    width: rem(16px);
    height: rem(16px);
  }
}

.contact-text {
  line-height: 1.5;
}

.small-link {
  transition: color 0.2s;

  &:hover,
  &.current {
    color: $c-white;
  }

  &.current {
    pointer-events: none;
  }

}
</style>
