import packageConfig from '../package.json'

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import Platform from '@monogrid/js-utils/lib/Platform'

import router from './plugins/router.js'
import './plugins/analytics.js'
import './plugins/locale.js'
import VueMeta from 'vue-meta'
import VueEvents from 'vue-event-handler'
import Preloader from './preloader'
import onlineApolloProvider from './services/CMS'
import SettingQuery from '@/graphql/GetSetting.gql'
import ListProjectQuery from '@/graphql/ListProject.gql'
import PortalVue from 'portal-vue'
import axios from 'axios'

// import CMSOffline from './services/CMSOffline'
// import cmsOfflineCatalog from './services/CMSOfflineCatalog.json'
// import cmsOfflineSchema from './services/CMSOfflineSchema.graphql'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

// usage: https://github.com/declandewet/vue-meta
Vue.use(VueMeta)
// usage: https://github.com/sandeepk01/vue-event-handler
Vue.use(VueEvents)

// usage: https://github.com/LinusBorg/portal-vue
Vue.use(PortalVue)
//
//
// Initialization of the preloader app (placed on the HTML)
//
//
const preloaderInstance = new Vue(Preloader)

// management of languages
router.beforeEach((to, from, next) => {
  // set current language on change route
  preloaderInstance.visible = true
  if (packageConfig.multilanguage) {
    if (to.params.language) {
      Vue.config.language = to.params.language || 'en'
    }
  } else {
    Vue.config.language = 'en'
  }
  next()
})

function getCookie (name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

//
//
// Initialization of the app (placed on the HTML)
//
//
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  /**
   * this is an example of using the online server in all the stages
   * and using offline data in a production stage (for example if deployed on client servers)
   * rename the production stage to "production" if you want to use this configuration
   */
  // apolloProvider: process.env.SERVICE_STAGE !== 'my-production-stage' ? onlineApolloProvider : new CMSOffline(cmsOfflineCatalog, cmsOfflineSchema).getProvider(),
  /**
   * use this if you want the online-only version of the CMS data
   */
  apolloProvider: onlineApolloProvider,
  apollo: {
    Settings: {
      query: SettingQuery,
      variables () {
        return {
          status: 'PUBLISHED',
          id: '757eb7dc-6ba0-4905-8bd1-5a19a03e1fa2',
          variants: [this.$language.current === 'en' ? 'base' : this.$language.current]
        }
      }
    },
    Projects: {
      query: ListProjectQuery,
      variables () {
        return {
          status: 'PUBLISHED',
          variants: [this.$language.current === 'en' ? 'base' : this.$language.current]
        }
      },
      update (data) {
        return data.Projects && data.Projects.items ? data.Projects.items : null
      }
    }
  },
  data () {
    const u = getCookie('fedUs')
    return {
      userIsLogged: u ? u.trim() : false,
      preloader: preloaderInstance,
      process: {
        env: process.env
      }
    }
  },
  computed: {
    platform () {
      return Platform
    },
    multilanguage () {
      return packageConfig.multilanguage
    }
  },
  methods: {
    async sendEvent (data) {
      this.$gtm && this.$gtm.trackEvent(data)
      try {
        if (this.process.env.SERVICE_STAGE !== 'production') {
          console.log({ origin: window.location.origin, ...data })
        }
        await axios.post('https://fedlive.mmh-tech.com/site/store', { data: { origin: window.location.origin, ...data } })
      } catch (err) {
        if (this.process.env.SERVICE_STAGE !== 'production') {
          console.log(err)
        }
      }
    }
  },
  // this block allows passing props to the main App
  // these props are set by html-webpack-plugin in the html
  // and the values are taken from git-revision-webpack-plugin
  render (createElement) {
    return createElement(App, {
      props: {
        preloader: preloaderInstance
      }
    })
  }
})
