<template>
  <Cta
    class="menu-button"
    :aria-label="menuIsOpen ? $root.Settings.menuCloseLabel : $root.Settings.menuOpenLabel"
  >
    <span
      class="menu-labels"
      :class="{'isOpen' : menuIsOpen}"
    >
      <span class="open">
        {{$root.Settings.menuOpenLabel}}
      </span>
      <span class="close">
        {{$root.Settings.menuCloseLabel}}
      </span>
    </span>

    <template v-slot:after>
      <ThemeSwitcher class="theme-switcher" />
    </template>
  </Cta>
</template>

<script>
import Cta from '@/atoms/Cta'
import ThemeSwitcher from '@/atoms/ThemeSwitcher'
export default {
  name: 'MenuButton',
  props: {
    menuIsOpen: { type: Boolean }
  },
  components: { Cta, ThemeSwitcher }
}
</script>

<style lang="scss" scoped>

.menu-button {
  position: fixed;
  z-index: $z-toggle-menus;
  bottom: rem(16px);
  left: 50%;

  transform: translateX(-50%);
  transition: transform 0s 0.5s, left 0s 0.5s, opacity 0s 0.5s;

  @screen beforeMd {
    html[data-page='ProjectsList'] & {
      left: rem(16px);

      transform: none;
    }
  }
}

.theme-switcher {
  z-index: 3;

  width: rem(48px);
  height: rem(32px);

  @screen md {
    width: rem(60px);
    height: rem(38px);
  }
  margin-right: rem(4px);

}

::v-deep .cta-label {
  display: inline-block;

  width: rem(90px);

  @screen md {
    width: rem(110px);
  }
}

.menu-labels {
  display: inline-block;

  width: 100%;
  height: 100%;

  span {
    position: absolute;
    top: 50%;
    left: 50%;

    display: inline-block;

    transform: translate(-50%, -50%);
    transition: opacity 0.5s, transform 0.5s;
  }

  .close {
    opacity: 0;
    transform: translate(0%, -50%);
  }

  &.isOpen {
    .open {
      opacity: 0;
      transform: translate(-100%, -50%);
    }

    .close {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
