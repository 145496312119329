<template>
  <button
    type="button"
    :class="{ dark }"
    @click.stop="onClick"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.786 20.259">
      <path d="M14.331 5.938L8.393-.005 2.455 5.938a8.394 8.394 0 1011.876 0zM8.393 18.171A6.291 6.291 0 013.945 7.428l4.448-4.459z" fill="currentColor"/>
    </svg>
  </button>
</template>

<script>
const htmlElement = document.getElementsByTagName('html')[0]

export default {
  name: 'ThemeSwitcher',
  data () {
    return {
      dark: false
    }
  },
  mounted () {
    this.$events.on('current-category', this.onCategoryChange)
  },
  beforeDestroy () {
    this.$events.off('current-category', this.onCategoryChange)
  },
  watch: {
    '$route.name': {
      immediate: true,
      handler () {
        htmlElement.setAttribute('data-page', this.$route.name)
        this.onCategoryChange('')
      }
    }
  },
  methods: {
    onCategoryChange (cat) {
      htmlElement.setAttribute('data-cat', cat)
    },
    onClick () {
      this.dark = !this.dark
      htmlElement.setAttribute('data-theme', this.dark ? 'dark' : '')
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  border-radius: rem(13px 13px 3px 13px);

  color: $c-white;
  outline: none !important;
  transition: opacity 0.2s, background-color 0.2s, color 0.2s;

  &:hover {
    opacity: 0.8;
  }

  &.dark {
    background: $c-red;
  }
}

button {
  @include themify-rule('background-color', 'bg', 'base-dark');
  @include themify-rule('color', 'text', 'base-dark');

  &.dark {
    @include themify-rule('background-color', 'bg', 'base');
    @include themify-rule('color', 'text', 'base');
  }

  html[data-page='BehindTheAward'] & {
    @include themify-rule('background-color', 'bg', 'behind-dark');
    @include themify-rule('color', 'text', 'behind-dark');

    &.dark {
      @include themify-rule('background-color', 'bg', 'behind');
      @include themify-rule('color', 'text', 'behind');
    }
  }

  @each $cat, $palette in $page-types {
    html[data-page='SingleProject'][data-cat='#{$cat}'] & {
      @include themify-rule('background-color', 'bg', 'cat-'+$palette+'-dark');
      @include themify-rule('color', 'text', 'cat-'+$palette+'-dark');

      &.dark {
        @include themify-rule('background-color', 'bg', 'cat-'+$palette);
        @include themify-rule('color', 'text', 'cat-'+$palette);
      }

    }
  }
}

svg {
  width: rem(16px);
  height: rem(16px);

  margin: 0 auto;
  @screen md {
    width: rem(20px);
    height: rem(20px);
  }
}
</style>
