<template>
  <div class="app-loading">
    <div class="bg" :class="`bg-${1+(this.colorIndex)%4}`" role="presentation"></div>
    <div class="bg" :class="`bg-${1+(this.colorIndex+1)%4}`" role="presentation"></div>
    <div class="logo-wrapper" :class="{initialized}">
      <LogoSvg class="logo"/>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import Platform from '@monogrid/js-utils/lib/Platform'
import LogoSvg from '@/assets/svg/logo.svg'

export default {
  // avoid touching the actual element on the HTML
  // if this is a prerenderer
  el: !Platform.prerenderer ? '#app-loading' : null,
  components: { LogoSvg },
  async mounted () {
    this.createTl()
    this.tl.tweenTo('show', {
      onComplete: () => {
        this.initialized = true
        if (!this.visible) {
          this.hide()
        }
      }
    })
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
  },
  data () {
    return {
      initialized: false,
      visible: true,
      completedOut: false,
      colorIndex: 0
    }
  },
  watch: {
    visible (isVisible) {
      if (!this.initialized) return
      if (isVisible) {
        this.completedOut = false
        this.tl.tweenTo('show')
      } else {
        this.hide()
      }
    }
  },
  methods: {
    createTl () {
      this.tl = gsap.timeline({ paused: true })
      this.tl.addLabel('start')
      this.tl.fromTo('.app-loading', { pointerEvents: 'none' }, { pointerEvents: 'auto', duration: 0.1 })
      this.tl.fromTo('.app-loading .bg', { scale: 0 }, { scale: 1.2, transformOrigin: 'left bottom', duration: 0.5, stagger: 0.2, ease: 'power2.inout' })
      this.tl.fromTo('.app-loading .logo-wrapper', { opacity: 0 }, { opacity: 1, duration: 0.1 }, '<+=0.2')
      this.tl.addLabel('show')
      this.tl.to('.app-loading .bg', { scale: 0, transformOrigin: 'right top', duration: 0.5, stagger: -0.2, ease: 'power2.inout' })
      this.tl.to('.app-loading .logo-wrapper', { opacity: 0, duration: 0.2 }, '-=0.2')
      this.tl.addLabel('hide')
    },
    hide () {
      this.tl.tweenTo('hide', {
        onComplete: () => {
          this.colorIndex++
          this.completedOut = true
          this.tl.seek(0)
        }
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.app-loading {
  position: fixed;
  z-index: $z-loader;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.logo-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 136px;
  height: 20px;

  margin-top: -10px;
  margin-left: -68px;

  &:not(.initialized) {
    opacity: 1 !important;
  }
}

.logo {
  width: 100%;
  height: 100%;

  animation: logoFlash 1s infinite alternate;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  // border-radius: 0 20% 20% 0;

  transform: scale(0);
  transform-origin: bottom left;
}

.bg-1 {
  background: $c-violet;
}

.bg-2 {
  background: $c-green;
}

.bg-3 {
  background: $c-salmon;
}

.bg-4 {
  background: $c-red;
}

@keyframes logoFlash {
  from {opacity: 1;}
  to {opacity: 0;}
}
</style>
