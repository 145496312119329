<template>
  <div
    class="cta-wrapper"
    :class="{loading}"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @touchstart="onMouseEnter"
    @touchend="onMouseLeave"
  >
    <component
      :is="$attrs.to ? 'router-link' : 'button'"
      :type="$attrs.to ? null : $attrs.buttonType || 'button'"
      v-bind="$attrs"
      class="cta"
    >
      <div class="bg" :class="`bg-${1+(this.colorIndex)%4}`" role="presentation"></div>
      <div class="bg" :class="`bg-${1+(this.colorIndex+1)%4}`" role="presentation"></div>
      <!-- <div class="bg bg-4" role="presentation"></div>
      <div class="bg bg-1" role="presentation"></div> -->
      <div class="bg bg-5" role="presentation"></div>
      <span class="cta-label">
        <slot/>
      </span>
      <slot name="after"></slot>
    </component>
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: 'Cta',
  inheritAttrs: false,
  props: {
    loading: { type: Boolean }
  },
  data () {
    return {
      colorIndex: 0
    }
  },
  async mounted () {
    await this.$nextTick()
    this.createTl()
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
  },
  methods: {
    createTl () {
      this.tl = gsap.timeline({ paused: true })
      this.tl.addLabel('hide')
      this.tl.fromTo(this.$el.querySelectorAll('.bg'), { xPercent: -100 }, { xPercent: 100, duration: 1, stagger: 0.15, ease: 'power2.inout' })
      this.tl.addLabel('show')
    },
    onMouseEnter () {
      this.tl.tweenTo('show')
    },
    onMouseLeave () {
      this.tl.tweenTo('hide', {
        onComplete: () => {
          this.colorIndex++
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cta-wrapper {
  display: inline-block;

  &.loading {
    cursor: progress;
  }

  &:hover {
    .cta-label {
      transform: scale(1.1);
    }
  }
}

.cta {
  position: relative;

  display: inline-flex;

  overflow: hidden;

  max-width: rem(290px);

  align-items: center;

  border-radius: rem(15px 15px 3px 15px);

  background: $c-blackest;
  color: $c-white;
  outline: none;
  transition: opacity 0.2s;
  will-change: transform;

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.cta-label {
  position: relative;
  z-index: 2;

  padding: rem(10px 30px 8px);

  transition: transform 0.3s;

  user-select: none;
  will-change: transform;
}

.bg {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 0;

  width: 250%;
  height: 0%;

  padding-top: 100%;

  border-radius: 50%;

  margin-top: -50%;

  transform: translateX(0);
  transform-origin: center;
  will-change: transform;

}

.bg-1 {
  background: $c-violet;
}

.bg-2 {
  background: $c-green;
}

.bg-3 {
  background: $c-salmon;
}

.bg-4 {
  background: $c-red;
}

.bg-5 {
  background: $c-blackest;
}

</style>
