<template>
  <label class="switch">
    <slot />
    <input
      type="checkbox"
      :checked="value"
      @input="onUpdate"
    >
    <span class="slider"></span>
  </label>
</template>

<script>
export default {
  name: 'SwitchComponent',
  props: {
    defaultValue: { type: Boolean, default: false }
  },
  data () {
    return {
      value: this.defaultValue
    }
  },
  methods: {
    onUpdate (e) {
      this.value = e.target.checked
      this.$emit('update', this.value)
    },
    reset (forcedValue) {
      this.value = forcedValue !== undefined ? forcedValue : this.defaultValue
    }
  }
}
</script>

<style lang="scss" scoped>
.switch {
  position: relative;

  display: flex;

  width: 100%;

  align-items: center;

  justify-content: space-between;

  cursor: pointer;
}

.switch input {
  width: 0;
  height: 0;

  opacity: 0;
}

.slider {
  position: relative;

  width: rem(54px);
  height: rem(28px);

  border-radius: rem(7px);

  background-color: #4c4c4c;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    background-color: #ccc;
  }
}

.slider::before {
  position: absolute;
  bottom: rem(4px);
  left: rem(4px);

  width: rem(20px);
  height: rem(20px);

  border-radius: rem(4px);

  background-color: $c-black;
  content: '';
  transition: 0.4s;
}

// input:checked + .slider {
//   background-color: #2196f3;
// }

input:checked + .slider::before {
  transform: translateX(rem(26px)) rotate(90deg);
}
</style>
