var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"categories"},[_c('div',{staticClass:"bg"}),_vm._v(" "),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"stagger mb-3"},[_c('SwitchComponent',{ref:"all",style:({'pointer-events': _vm.filters.length === 0 ? 'none' : 'auto'}),attrs:{"defaultValue":true},on:{"update":function (e) { return _vm.onChange('all', e); }}},[_vm._v("\n        "+_vm._s(_vm.$root.Settings.allCategoriesLabel)+"\n      ")])],1),_vm._v(" "),_vm._l((_vm.Categories),function(ref){
var id = ref.id;
var title = ref.title;
var colorPalette = ref.colorPalette;
return _c('div',{key:id,staticClass:"stagger mb-3",class:{
        'palette-Labels': colorPalette === 'Labels',
        'palette-Packaging': colorPalette === 'Packaging',
        'palette-Publishing': colorPalette === 'Publishing',
        'palette-CorporateIdentity': colorPalette === 'CorporateIdentity',
      }},[_c('SwitchComponent',{ref:"categories",refInFor:true,on:{"update":function (e) { return _vm.onChange(id, e); }}},[_vm._v("\n        "+_vm._s(title)+"\n      ")])],1)}),_vm._v(" "),_c('div',{staticClass:"stagger"},[_c('SwitchComponent',{ref:"hp",staticClass:"palette-hp",on:{"update":function (e) { return _vm.onChange('hp', e); }}},[_vm._v("\n        Digital HP Indigo\n      ")])],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }