import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'
import vueRouter from '@/plugins/router'
import Platform from '@monogrid/js-utils/lib/Platform'

const isTesting = window.location.href.indexOf('localhost') !== -1 ||
                window.location.href.indexOf('-develop') !== -1 // || window.location.href.indexOf('-master') !== -1

// only if no prerenderer
if (!Platform.prerenderer && !isTesting) {
  Vue.use(VueGtm, {
    id: 'GTM-TG7PZMV',
    router: vueRouter
  })
}
