<template>
  <CookieLaw>
    <div slot-scope="props" class="cookie-container">
      <div class="md:pr-8">
        <template v-if="$language.current === 'en'">
          <p>
            This site uses profiling cookies, including third-party cookies, to provide you with services and advertising better tailored to your preferences. If you click on this banner or continue to browse by clicking anywhere on the page, you accept the use of these cookies. For more information on how to change your consent and browser settings in order to disable cookies, please read our <a target="_blank" rel="noopener" :href="$root.Settings.cookiePolicyUrl">Cookie Policy</a>.
          </p>
        </template>
        <template v-else>
          <p>
            Questo sito utilizza cookie di profilazione, anche di terze parti, per fornirti servizi e pubblicità in linea con le tue preferenze. Se chiudi questo banner o prosegui con la navigazione cliccando un elemento della pagina ne accetti l’utilizzo. Per maggiori informazioni su come modificare il consenso e le impostazioni dei browser per negare l’utilizzo dei cookie ti invitiamo a leggere la nostra <a target="_blank" rel="noopener" :href="$root.Settings.cookiePolicyUrl">Cookie Policy</a>.
          </p>
        </template>
      </div>
      <button
        action="button"
        @click.prevent="props.accept"
      >
        OK
      </button>
    </div>
  </CookieLaw>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'BannerCookie',
  components: {
    CookieLaw
  },
  methods: {
    addListener () {
      // const TAG = this.$refs.text.$el.children[0].firstElementChild
      // TAG.addEventListener('click', e => {
      //   e.preventDefault()
      //   if (this.$route.name !== 'PrivacyPage') {
      //     this.$router.push({ name: 'PrivacyPage' })
      //   }
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.Cookie {
  position: fixed;
  z-index: $z-cookie-banner;

  width: 100%;
  height: auto;

  padding: rem(22px);

  background-color: $c-black;
  color: $c-white;

  .cookie-container {
    position: relative;

    display: flex;

    width: 100%;
    max-width: rem(1400px);

    align-items: center;
    justify-content: center;

    margin: 0 auto;

    @screen beforeMd {
      height: auto;

      flex-direction: column;
      align-items: flex-end;
    }

  }

}

p {
  font-size: rem(13px);
  line-height: 1.2;

  a {
    text-decoration: underline;
  }
}

button {
  display: block;

  min-width: rem(116px);

  padding: rem(12px 0 8px);

  border: 2px solid $c-white;

  margin: rem(12px) auto 0;

  background-color: $c-black;
  color: $c-white;

  font-family: 'Fedrigoni-Banner';
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
  @screen md {
    margin-top: 0;
  }

  &:hover {
    background-color: $c-white;
    color: $c-black;
  }
}
// ::v-deep a {
//   color: $color-text-dark;
// }
</style>
