<template>
  <div class="categories">
    <div class="bg"></div>
    <div class="wrapper">
      <div class="stagger mb-3">
        <SwitchComponent
          ref="all"
          :defaultValue="true"
          @update="e => onChange('all', e)"
          :style="{'pointer-events': filters.length === 0 ? 'none' : 'auto'}"
        >
          {{$root.Settings.allCategoriesLabel}}
        </SwitchComponent>
      </div>
      <div
        v-for="({id, title, colorPalette}) in Categories"
        :key="id"
        class="stagger mb-3"
        :class="{
          'palette-Labels': colorPalette === 'Labels',
          'palette-Packaging': colorPalette === 'Packaging',
          'palette-Publishing': colorPalette === 'Publishing',
          'palette-CorporateIdentity': colorPalette === 'CorporateIdentity',
        }"
      >
        <SwitchComponent ref="categories" @update="e => onChange(id, e)">
          {{title}}
        </SwitchComponent>
      </div>
      <div class="stagger">
        <SwitchComponent
          ref="hp"
          class="palette-hp"
          @update="e => onChange('hp', e)"
        >
          Digital HP Indigo
        </SwitchComponent>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import ListCategoriesQuery from '@/graphql/ListProjectCategory.gql'
import SwitchComponent from '@/atoms/SwitchComponent'

export default {
  name: 'CategoryFilterss',
  props: {
    isOpen: { type: Boolean }
  },
  components: {
    SwitchComponent
  },
  data () {
    return {
      all: true,
      filters: []
    }
  },
  apollo: {
    Categories: {
      query: ListCategoriesQuery,
      variables () {
        return {
          status: 'PUBLISHED',
          variants: [this.$language.current === 'en' ? 'base' : this.$language.current]
        }
      },
      update (data) {
        return data.Categories && data.Categories.items ? data.Categories.items : null
      }
    }
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
  },
  watch: {
    isOpen (value) {
      this.tl && this.tl.tweenTo(value ? 'open' : 'close')
    },
    Categories: {
      immediate: true,
      handler () {
        this.$nextTick(this.createTl)
      }
    }
  },
  methods: {
    createTl () {
      this.tl = gsap.timeline({ paused: true })
      this.tl.addLabel('close')
      this.tl.fromTo(this.$el, { autoAlpha: 0, pointerEvents: 'none' }, { autoAlpha: 1, pointerEvents: 'auto', duration: 0.1 })
      this.tl.fromTo(this.$el.querySelector('.bg'), { scale: 0 }, { scale: 1, duration: 0.5 })
      this.tl.fromTo(this.$el.querySelectorAll('.stagger'), { autoAlpha: 0, y: 20 }, { autoAlpha: 1, y: 0, duration: 0.2, stagger: 0.05 }, '-=0.2')
      this.tl.addLabel('open')
    },
    onChange (type, value) {
      if (type === 'all') {
        if (value) {
          this.$refs.categories.forEach(cat => {
            cat.reset()
          })
          this.$refs.hp.reset()
          this.filters.splice(0, this.filters.length)
        }
      } else {
        if (value) {
          this.filters.push(type)
          this.$refs.all.reset(false)
        } else {
          this.$delete(this.filters, this.filters.findIndex(filter => filter === type))
          if (this.filters.length === 0) {
            this.$refs.all.reset(true)
          }
        }
      }
      this.$events.emit('change:projects-filter', this.filters)
    }
  }
}
</script>

<style lang="scss" scoped>
.categories {
  position: fixed;

  z-index: $z-menus;
  right: rem(16px);
  bottom: rem(32px);

  width: 90%;
  max-width: rem(300px);

  padding: rem(16px 16px 24px);

  color: $c-white;

  @screen md {
    padding: rem(32px 16px 48px);
  }

  @screen lg {
    padding: rem(48px 32px);
  }
}

.wrapper {
  html:not(.desktop) & {
    max-height: 70vh;

    overflow-y: auto;
  }

  @screen beforeMd {
    padding: rem(24px 0);
    /* stylelint-disable-next-line */
    -webkit-mask-image: -webkit-linear-gradient(top,rgba(0,0,0,0) 0px,#000 20px,#000 calc(100% - 20px),rgba(0,0,0,0) 100%);
  }
}

.bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: rem(12px);

  background: $c-black;
  transform-origin: bottom right;
}
// stylelint-disable-next-line
::v-deep input + .slider:hover {
  @each $cat, $palette in $page-types {

    // stylelint-disable-next-line
    .palette-#{$cat} & {
      // stylelint-disable-next-line
        background-color: rgba(map-get($themes, 'cat-'+$palette+'-bg'), $alpha: 0.8);
    }
  }
  // stylelint-disable-next-line
  .palette-hp & {
    background-color: rgba($c-hp, $alpha: 0.8);
  }
}

// stylelint-disable-next-line
::v-deep input:checked + .slider {
  background-color: $c-white;
  @each $cat, $palette in $page-types {

    // stylelint-disable-next-line
    .palette-#{$cat} & {
      // stylelint-disable-next-line
      @include themify-rule('background-color', 'bg', 'cat-'+$palette);
    }
  }
  // stylelint-disable-next-line
  .palette-hp & {
    background-color: $c-hp;
  }
}
</style>
